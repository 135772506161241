import { combineReducers, configureStore } from '@reduxjs/toolkit';
import productsReducer from './products';
import filtersReducer from './filter';
import shopsReducer from './shops';
import categoriesReducer from './categories';
import basketReducer from './basket';
import commonReducer from './common';

const rootReducer = combineReducers({
  products: productsReducer,
  filters: filtersReducer,
  shops: shopsReducer,
  categories: categoriesReducer,
  basket: basketReducer,
  common: commonReducer,
});

export const setupStore = () => {
  return configureStore({ reducer: rootReducer });
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
