import { getSettingsReq } from '../../API';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchSettings = createAsyncThunk('common/fetchAll', async (_, thunkAPI) => {
  try {
    const urlParams = new URLSearchParams(window.location.search);
    const shopId = urlParams.get('shopId');
    if (!shopId) {
      throw new Error('Shop ID не указан'); // или другая обработка ошибки
    }
    localStorage.setItem('shopId', shopId);
    return await getSettingsReq(shopId);
  } catch (e) {
    return thunkAPI.rejectWithValue('Не удалось загрузить магазины');
  }
});
