import React, { FC, ReactNode } from 'react';
import { Header } from '../Header';
import { MoveTopButton } from '../MoveTopButton';
import { useAppSelector } from '../../hooks/redux';

import './styles.css';

interface WrapperProps {
  children: ReactNode;
}

export const Wrapper: FC<WrapperProps> = ({ children }) => {
  const {
    settings: { color },
  } = useAppSelector(state => state.common);

  return (
    <div className="wrapper" style={{ backgroundColor: color }}>
      <Header />
      {children}
      <MoveTopButton />
    </div>
  );
};
