import { IWorkTime } from '../models/IWorkTime';
import { IShop, ShopTypes } from '../models/IShop';
import { getProductsType, SendBasketProductReqData, SendBasketReqData } from '../API';
import { AppDispatch } from '../store/store';
import { IProduct } from '../models/IProduct';
import { Totals } from '../hooks/useTotals';
import { resetProducts } from '../store/products';
import { resetFilter } from '../store/filter';
import { resetCategory } from '../store/categories';
import { fetchProducts } from '../store/products/actions';
import { fetchShopFilters } from '../store/filter/actions';
import { fetchCategories } from '../store/categories/actions';
import { fetchBasket, postBasketIsSend, sendBasketToChat } from '../store/basket/actions';
import { fetchShops } from '../store/shops/actions';

export const generateWorkTime = (from: IWorkTime, to: IWorkTime) => {
  const oursFrom = `${from?.hour < 10 ? '0' : ''}${from?.hour}:00`;
  const oursTo = to?.hour ? `${to?.hour < 10 ? '0' : ''}${to?.hour}:00` : '23:59';

  return `${oursFrom} - ${oursTo}`;
};

export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const resetShopData = (dispatch: AppDispatch) => {
  dispatch(resetProducts());
  dispatch(resetFilter());
  dispatch(resetCategory());
};

export const sendBasket = (
  currentShop: IShop,
  basketProducts: { [key in ShopTypes]: IProduct[] },
  totals: Totals,
  products: IProduct[],
  dispatch: AppDispatch,
) =>
  new Promise<void>(async resolve => {
    const resData = generateSendBasketReqData(currentShop, basketProducts, totals);

    await dispatch(postBasketIsSend({ products, shopId: currentShop.apiId }));
    await dispatch(sendBasketToChat({ resData, shopId: currentShop.apiId }));

    resolve();
  });

export const getInitialData = (shop: IShop, dispatch: AppDispatch, getProducts?: boolean) =>
  new Promise<void>(async resolve => {
    const params: getProductsType = {
      shopId: shop.apiId,
    };

    await dispatch(fetchShops());

    await dispatch(fetchBasket(shop));

    await dispatch(fetchCategories(shop.apiId));
    await dispatch(fetchShopFilters(shop.apiId));

    if (getProducts) {
      await dispatch(fetchProducts(params));
    }

    resolve();
  });

export const debounce = (fn: (...args: any[]) => void, ms = 300) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };
};

export const generateSendBasketReqData = (
  shop: IShop,
  products: { [key in ShopTypes]: IProduct[] },
  totals: Totals,
) => {
  const res: SendBasketReqData[] = [];

  const curProducts = products?.[shop.typeId];
  const curEconomy = totals?.[shop.typeId];

  if (shop && curProducts?.length) {
    const usersBasketDTOs: SendBasketProductReqData[] = curProducts.map(p => ({
      goodCode: p.id,
      goodName: p.name,
      amount: p.amount,
      oldPrice: p.oldPrice || 0,
      newPrice: p.price,
    }));

    res.push({
      shopAdress: shop.address,
      shopFormat: '',
      economy: curEconomy?.totalShopBenefit ? Number(curEconomy?.totalShopBenefit) : 0,
      usersBasketDTOs,
    });
  }

  return res;
};

export const randomInteger = (min: number, max: number) =>
  Math.round(min - 0.5 + Math.random() * (max - min + 1));

export const numberWithSpaces = (number: string | number): string => {
  const parts = number.toString().split('.');

  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  return parts.join('.');
};
