import {
  clearBasketReq,
  getBasketReq,
  postBasketIsSendReq,
  postBasketReq,
  sendBasketReq,
  SendBasketReqData,
} from '../../API';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { IProduct } from '../../models/IProduct';
import { IShop } from '../../models/IShop';

export const fetchBasket = createAsyncThunk('basket/fetchAll', async (shop: IShop, thunkAPI) => {
  try {
    const products: IProduct[] = await getBasketReq(shop.apiId);

    if (products.length) {
      await postBasketReq({ products, shopId: shop.apiId });
    }

    return { products, shopType: shop.typeId };
  } catch (e) {
    return thunkAPI.rejectWithValue('Не удалось загрузить корзину');
  }
});

export const clearBasket = createAsyncThunk('basket/clear', async (shop: IShop, thunkAPI) => {
  try {
    await clearBasketReq(shop.apiId);
    return shop.typeId;
  } catch (e) {
    return thunkAPI.rejectWithValue('Не удалось очистить корзину');
  }
});

export const setProductToBasket = createAsyncThunk(
  'basket/addProductToBasket',
  async ({ products, shop }: { products: IProduct[]; shop: IShop }, thunkAPI) => {
    try {
      await postBasketReq({ products, shopId: shop.apiId });

      return { product: products[0], shopType: shop.typeId };
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось обновить продукт');
    }
  },
);

export const postBasketIsSend = createAsyncThunk(
  'basket/postBasketIsSend',
  async ({ products, shopId }: { products: IProduct[]; shopId: string }, thunkAPI) => {
    try {
      await postBasketIsSendReq({ products, shopId });
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось обновить продукт');
    }
  },
);

export const sendBasketToChat = createAsyncThunk(
  'basket/setBasketToChat',
  async ({ resData, shopId }: { resData: SendBasketReqData[]; shopId: string }, thunkAPI) => {
    try {
      await sendBasketReq(resData, shopId);
    } catch (e) {
      return thunkAPI.rejectWithValue('Не удалось отправить корзину в чат');
    }
  },
);
