import React, { FC, useEffect } from 'react';
import { Routes, Route, useLocation, useParams } from 'react-router-dom';
import { LocationProvider } from './components/LocationProvider';
import { BasketList, HomePage } from './pages';
import { useTelegram } from './hooks/useTelegram';
import { useAppDispatch } from './hooks/redux';
import { getShopsReq } from './API';
import { getInitialData } from './utils';
import { fetchSettings } from './store/common/actions';

import './assets/fonts/stylesheet.css';
import './styles/variables.css';
import './styles/colors.css';
import './App.css';

const App: FC = () => {
  const dispatch = useAppDispatch();
  const { onExpand, disableClose } = useTelegram();

  useEffect(() => {
    onExpand();
    disableClose();

    (async () => {
      await dispatch(fetchSettings());
    })();

    const search = decodeURIComponent(location.pathname.replace('/search_', '').replace('/', ''));

    if (!!search) {
      return;
    }

    (async () => {
      const shopId = localStorage.getItem('shopId');
      if (!shopId) {
        throw new Error('Shop ID не указан'); // или другая обработка ошибки
      }
      const curShop = await getShopsReq(shopId);

      if (curShop?.length) {
        await getInitialData(curShop[0], dispatch, true);
      }
    })();
  }, []);

  return (
    <>
      <LocationProvider>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/:search" element={<HomePage />} />
          <Route path="/basket" element={<BasketList />} />
        </Routes>
      </LocationProvider>
    </>
  );
};

export default App;
