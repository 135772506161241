import { CommonProps } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSettings } from './actions';
import { ISetting } from '../../models/ISetting';

const initialState: CommonProps = {
  settings: { image: '', color: '' },
  isLoadingSettings: false,
  error: '',
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchSettings.pending.type]: state => {
      state.isLoadingSettings = true;
    },
    [fetchSettings.fulfilled.type]: (state, action: PayloadAction<ISetting>) => {
      state.isLoadingSettings = false;
      state.error = '';
      state.settings = action.payload;
    },
    [fetchSettings.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingSettings = false;
      state.error = action.payload;
    },
  },
});

export const {} = commonSlice.actions;

const reducer = commonSlice.reducer;
export default reducer;
