import React, { FC, useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { IProduct } from '../../models/IProduct';
import { setProductToBasket } from '../../store/basket/actions';

import './styles.css';
import { IShop } from '../../models/IShop';
import { numberWithSpaces } from '../../utils';

interface ProductProps {
  product: IProduct;
  shop?: IShop;
}

export const Product: FC<ProductProps> = ({ product, shop }) => {
  const dispatch = useAppDispatch();
  const {
    basket: { basketProducts },
    products: { products },
    shops: { currentShop },
  } = useAppSelector(state => state);

  const curShop = useMemo(() => shop || currentShop, [shop, currentShop]);

  const amount = useMemo(() => {
    if (!basketProducts?.[curShop.typeId]) {
      return product.amount;
    }

    const productFromBasket = basketProducts[curShop.typeId].find(item => item.id === product.id);

    if (productFromBasket) {
      return productFromBasket.amount;
    }

    return product.amount;
  }, [basketProducts, product, products, setProductToBasket, setProductToBasket, currentShop]);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(!!amount);
  }, [amount]);

  const handleSetProductToBasket = (newProduct: IProduct) => {
    dispatch(setProductToBasket({ products: [newProduct], shop: curShop }));
  };

  const handleAddToBasket = () => {
    setIsOpen(true);

    const newProduct = { ...product, amount: amount + 1 };
    handleSetProductToBasket(newProduct);
  };

  const handleRemoveFromBasket = () => {
    if (amount - 1 < 1) {
      setIsOpen(false);
    }

    const newProduct = { ...product, amount: amount - 1 };
    handleSetProductToBasket(newProduct);
  };

  const handleClearFromBasket = () => {
    setIsOpen(false);

    const newProduct = { ...product, amount: 0 };
    handleSetProductToBasket(newProduct);
  };

  return (
    <div className="product">
      <div className="product__content">
        {amount ? (
          <div className="product__clear" onClick={handleClearFromBasket}>
            <div className="product__clear-icon" />
          </div>
        ) : null}

        <img
          className="product__image"
          src={product?.image || 'https://via.placeholder.com/200x200/258DC8/E0F6FD'}
          alt=""
          loading="lazy"
          onClick={() => !isOpen && handleAddToBasket()}
        />

        {product.discount ? (
          <div className="product__discount" onClick={() => !isOpen && handleAddToBasket()}>
            -{product.discount}%
          </div>
        ) : null}

        <div className="product__actions">
          {isOpen && (
            <div className="product__control">
              <div className="product__shop-button bg-white" onClick={handleRemoveFromBasket}>
                <div className="product__minus-icon" />
              </div>
              <div className="product__shop-button bg-white" onClick={handleAddToBasket}>
                <div className="product__plus-icon" />
              </div>
            </div>
          )}
          <div className={`product__shop-button ${isOpen ? 'bg-red' : 'bg-white'}`}>
            {isOpen ? (
              <div className="product__amount">{amount}</div>
            ) : (
              <div className="product__shop-icon" onClick={handleAddToBasket} />
            )}
          </div>
        </div>
      </div>

      <div className="product__current-price">{numberWithSpaces(product.price.toFixed(2))}</div>

      {product.oldPrice && product.oldPrice !== product.price ? (
        <div className="product__old-price">{numberWithSpaces(product.oldPrice.toFixed(2))}</div>
      ) : null}

      <div className="product__description">{product.name}</div>
    </div>
  );
};

export default Product;
