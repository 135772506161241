import React, { FC, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useTotals } from '../../hooks/useTotals';
import { useAppSelector } from '../../hooks/redux';
import { SendButton } from '../SendButton';

import './styles.css';

export const Header: FC = () => {
  const {
    basket: { basketProducts },
    shops: { currentShop },
    common: {
      settings: { image, color },
    },
  } = useAppSelector(state => state);

  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { totalProductsCount } = useTotals();

  const isBasket = useMemo(() => pathname === '/basket', [pathname]);
  const [isShadowActive, setIsShadowActive] = useState<boolean>(false);

  const products = useMemo(() => basketProducts[currentShop.typeId], [currentShop, basketProducts]);

  const checkScroll = () => setIsShadowActive(!!window?.scrollY);

  useEffect(() => {
    setIsShadowActive(false);

    document?.addEventListener('scroll', checkScroll);

    return () => {
      document?.removeEventListener('scroll', checkScroll);
    };
  }, [pathname]);

  const handleClick = () => {
    navigate(isBasket ? '/catalog' : '/basket');
  };

  return (
    <div
      className={`header${isShadowActive ? ' header__shadow' : ''}`}
      style={{ backgroundColor: color }}
    >
      <div className="header__left">
        {isBasket && (
          <div className={'header__back'} onClick={() => navigate('/')}>
            ←
          </div>
        )}
        <Link style={{ textDecoration: 'none' }} to="/" className="header__magnit-icon-wrapper">
          <img src={image || '/img/gadget-lands-logo.svg'} alt="" className="header__magnit-icon" />
        </Link>
      </div>

      {!isBasket ? (
        <div className="header__basket-button" onClick={handleClick}>
          <div className="header__basket-icon" />
          <div className="header__basket-count">{totalProductsCount}</div>
        </div>
      ) : null}

      {isBasket && products?.length && products?.length > 2 ? <SendButton small /> : null}
    </div>
  );
};
