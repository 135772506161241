import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BasketState } from './types';
import {
  clearBasket,
  fetchBasket,
  postBasketIsSend,
  sendBasketToChat,
  setProductToBasket,
} from './actions';
import { IProduct } from '../../models/IProduct';
import { ShopTypes } from '../../models/IShop';

const initialState: BasketState = {
  basketProducts: {} as { [key in ShopTypes]: IProduct[] },
  isLoadingBasket: true,
  isLoadingSendBasket: false,
  error: '',
};

const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {},
  extraReducers: {
    [postBasketIsSend.pending.type]: state => {
      state.isLoadingSendBasket = true;
    },
    [postBasketIsSend.fulfilled.type]: state => {
      state.isLoadingSendBasket = false;
      state.error = '';
    },
    [postBasketIsSend.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingSendBasket = false;
      state.error = action.payload;
    },
    [fetchBasket.pending.type]: state => {
      state.isLoadingBasket = true;
    },
    [fetchBasket.fulfilled.type]: (
      state,
      action: PayloadAction<{ products: IProduct[]; shopType: ShopTypes }>,
    ) => {
      state.isLoadingBasket = false;
      state.error = '';
      state.basketProducts[action.payload.shopType] = action.payload.products;
    },
    [fetchBasket.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingBasket = false;
      state.error = action.payload;
    },
    [clearBasket.pending.type]: state => {
      state.isLoadingBasket = true;
    },
    [clearBasket.fulfilled.type]: (state, action: PayloadAction<ShopTypes>) => {
      state.isLoadingBasket = false;
      state.error = '';
      state.basketProducts[action.payload] = [];
    },
    [clearBasket.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingBasket = false;
      state.error = action.payload;
    },
    [setProductToBasket.pending.type]: state => {
      state.isLoadingBasket = true;
    },
    [setProductToBasket.fulfilled.type]: (
      state,
      action: PayloadAction<{ product: IProduct; shopType: ShopTypes }>,
    ) => {
      const { product: newProduct, shopType } = action.payload;

      state.isLoadingBasket = false;
      state.error = '';

      const index = state.basketProducts[shopType].findIndex(
        product => product.id === newProduct.id,
      );

      if (index >= 0) {
        if (newProduct.amount === 0) {
          state.basketProducts[shopType].splice(index, 1);
        } else {
          state.basketProducts[shopType][index] = newProduct;
        }
      } else {
        state.basketProducts[shopType] = [...state.basketProducts[shopType], newProduct];
      }
    },
    [setProductToBasket.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingBasket = false;
      state.error = action.payload;
    },
    [sendBasketToChat.pending.type]: state => {
      state.isLoadingSendBasket = true;
    },
    [sendBasketToChat.fulfilled.type]: state => {
      state.isLoadingSendBasket = false;
      state.error = '';
    },
    [sendBasketToChat.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingSendBasket = false;
      state.error = action.payload;
    },
  },
});

export const {} = basketSlice.actions;

const reducer = basketSlice.reducer;
export default reducer;
