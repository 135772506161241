import React, { FC, useMemo } from 'react';
import { sendBasket } from '../../utils';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { useTotals } from '../../hooks/useTotals';
import { useTelegram } from '../../hooks/useTelegram';
import { Loader } from '../Loader';

import './styles.css';

interface SendButtonProps {
  small?: boolean;
}

export const SendButton: FC<SendButtonProps> = ({ small }) => {
  const {
    basket: { basketProducts, isLoadingSendBasket },
    shops: { currentShop },
  } = useAppSelector(state => state);
  const { totals } = useTotals();
  const { onClose } = useTelegram();

  const dispatch = useAppDispatch();

  const products = useMemo(() => basketProducts[currentShop.typeId], [currentShop, basketProducts]);

  const handleClick = async () => {
    if (!currentShop || !products?.length || isLoadingSendBasket) {
      return;
    }

    await sendBasket(currentShop, basketProducts, totals, products, dispatch).then(() => onClose());
  };
  return (
    <>
      <div
        className={`${small ? 'basket__send-button-small' : 'basket__send-button'} ${
          isLoadingSendBasket ? 'loading' : 'not-loading'
        }`}
        onClick={handleClick}
      >
        {isLoadingSendBasket ? <Loader width="32px" /> : 'Заказать'}
      </div>
    </>
  );
};
