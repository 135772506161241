import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import './styles.css';

export const MoveTopButton: FC = () => {
  const { pathname } = useLocation();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const checkScroll = () => setIsVisible(window?.scrollY > 100);

  useEffect(() => {
    setIsVisible(false);

    document?.addEventListener('scroll', checkScroll);
    return () => {
      document?.removeEventListener('scroll', checkScroll);
    };
  }, [pathname]);

  const handleClick = () => {
    window?.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className={`move-top${isVisible ? '' : ' move-top__none'}`} onClick={handleClick}>
      <div className="move-top__image" />
    </div>
  );
};
