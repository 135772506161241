import React from 'react';
import { WrapperContent } from '../../components/WrapperContent';
import { Categories, FilterByCategory, Products } from './components';
import { Wrapper } from '../../components/Wrapper';

export const HomePage = () => {
  return (
    <Wrapper>
      <WrapperContent>
        <Categories />
        <FilterByCategory />
        <Products />
      </WrapperContent>
    </Wrapper>
  );
};
