import React from 'react';
import { Product } from '../../../../components/Product';
import { useAppSelector } from '../../../../hooks/redux';
import { Loader } from '../../../../components/Loader';

import './styles.css';

export const Products = () => {
  const { products, isLoadingProducts } = useAppSelector(state => state.products);

  return (
    <div className="products">
      {isLoadingProducts ? (
        <div className="products-loader">
          Загружаем список товаров...
          <Loader />
        </div>
      ) : products?.length ? (
        products.map((product, i) => <Product product={product} key={`product-${i}`} />)
      ) : (
        <div className="products-loader">Продукты по не найдены...</div>
      )}
    </div>
  );
};
