import { FC, useMemo } from 'react';
import { ShopTypes } from '../../../../models/IShop';
import { useAppSelector } from '../../../../hooks/redux';
import { useTotals } from '../../../../hooks/useTotals';
import { numberWithSpaces } from '../../../../utils';
import Product from '../../../../components/Product';

import './styles.css';

interface BasketShopProps {
  shopType: ShopTypes;
}

export const BasketShop: FC<BasketShopProps> = ({ shopType }) => {
  const { totals } = useTotals();
  const {
    basket: { basketProducts },
    shops: { currentShop },
  } = useAppSelector(state => state);

  const currentTotals = useMemo(
    () => totals?.[shopType] || undefined,
    [totals, shopType, currentShop],
  );

  const products = useMemo(() => basketProducts?.[shopType] || [], [basketProducts, shopType]);

  return currentShop && products.length && currentTotals ? (
    <>
      <div className="basket__products-list">
        {products?.map((product, i) => (
          <Product product={product} key={`basket-product-${i}-${product.id}`} />
        ))}
      </div>
      <div className={'basket__shop-totals'}>
        <div className={'basket__line'}>
          <p>Итого по магазину:</p>
          <p>{numberWithSpaces(currentTotals.totalShopPrice)}₽</p>
        </div>
        {Number(currentTotals.totalShopBenefit) ? (
          <div className={'basket__line basket__red'}>
            <p>Ваша выгода:</p>
            <p>{numberWithSpaces(Number(currentTotals.totalShopBenefit).toFixed(2))}₽</p>
          </div>
        ) : null}
      </div>
    </>
  ) : null;
};
