import React, { FC, ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

interface LocationProviderProps {
  children: ReactNode;
}

export const LocationProvider: FC<LocationProviderProps> = ({ children }) => {
  const location = useLocation();

  // useEffect(() => {
  //   const currentLocation = Cookies.get('location_app');
  //   if (!currentLocation) return;
  //   navigate(currentLocation);
  // }, []);

  useEffect(() => {
    const tenMinutes = new Date(new Date().getTime() + 10 * 60 * 1000);
    Cookies.set('location_app', location.pathname, {
      expires: tenMinutes,
    });
  }, [location]);

  return <>{children}</>;
};
