import React, { FC, useMemo } from 'react';
import { WrapperContent } from '../../components/WrapperContent';
import { useAppSelector } from '../../hooks/redux';
import { Wrapper } from '../../components/Wrapper';
import { BasketShop } from './components/BasketShop';
import { ShopTypes } from '../../models/IShop';
import { useTotals } from '../../hooks/useTotals';
import { numberWithSpaces } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { Loader } from '../../components/Loader';

import './styles.css';
import { SendButton } from '../../components/SendButton';

export const BasketList: FC = () => {
  const {
    basket: { basketProducts, isLoadingBasket },
    shops: { currentShop },
  } = useAppSelector(state => state);
  const { totalPrice } = useTotals();

  const navigate = useNavigate();

  const products = useMemo(() => basketProducts[currentShop.typeId], [currentShop, basketProducts]);

  return (
    <Wrapper>
      {isLoadingBasket ? (
        <div className={'basket__send-text mt'}>
          Загружаем корзину...
          <Loader />
        </div>
      ) : null}

      {products?.length && !isLoadingBasket ? (
        <WrapperContent
          noPadding
          underWrapper={
            <div className={'basket__under-content'}>
              <div className={'basket__total-price'}>
                <p>Всего:</p>
                <p>{numberWithSpaces(totalPrice)}₽</p>
              </div>
              <SendButton />
              <div className={'basket__send-text'}>
                Готовы сделать заказ? Нажмите кнопку Заказать, чтобы оформить ваш заказ.
              </div>
            </div>
          }
        >
          {Object.keys(basketProducts).map((key, i) => (
            <BasketShop key={`basket-shop-${i}`} shopType={Number(key) as ShopTypes} />
          ))}
        </WrapperContent>
      ) : null}

      {!products?.length && !isLoadingBasket ? (
        <>
          <div className={'basket__send-text mt'}>Ваша корзина пуста!</div>
          <div className={'basket__button'} onClick={() => navigate('/')}>
            К покупкам
          </div>
        </>
      ) : null}
    </Wrapper>
  );
};
