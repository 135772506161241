import { Interval, SortType } from './store/filter/types';
import { useTelegram } from './hooks/useTelegram';
import { IProduct } from './models/IProduct';
import { IShop } from './models/IShop';
import { BASE_URL, BASE_URL_SEND_FIRST, BASE_URL_SEND_SECOND, isDevApi } from './config';
import axios from 'axios';
import qs from 'qs';

const API = axios.create({
  baseURL: BASE_URL,
});

const sendAPIFirst = axios.create({
  baseURL: BASE_URL_SEND_FIRST,
});

const sendAPISecond = axios.create({
  baseURL: BASE_URL_SEND_SECOND,
});

API.interceptors.request.use((req: any) => {
  req.headers.CatalogToken = '1045b901-aa30-452e-938b-8d5e56c6001c';
  return req;
});

export const getCategoriesReq = async (shopId: string) => {
  const { chatId } = useTelegram();

  const response = await API.get(`/Catalog/Groups/?shopId=${shopId}&chatId=${chatId}`);
  return response.data;
};

export const getBasketReq = async (shopId: string) => {
  const { queryId, chatId } = useTelegram();

  const response = await API.get(
    `/Catalog/Basket/State/?shopApiId=${shopId}&chatId=${chatId}&queryId=${queryId}`,
  );
  return response.data;
};

export const postBasketReq = async ({
  products,
  shopId,
}: {
  products: IProduct[];
  shopId: string;
}) => {
  const { queryId, chatId } = useTelegram();

  const resBasket = products.map(product => ({
    goodCode: product.id,
    groupId: product.groupId,
    price: product.price,
    discount: product.discount,
    image: product.image,
    amount: product.amount,
    oldPrice: product.oldPrice,
    goodName: product.name,
    shopApiId: shopId,
  }));

  const response = await API.post(
    `/Catalog/Basket/State/?chatId=${chatId}&queryId=${queryId}&shopId=${shopId}`,
    resBasket,
  );
  return response.data;
};

export const postBasketIsSendReq = async ({
  products,
  shopId,
}: {
  products: IProduct[];
  shopId: string;
}) => {
  const { queryId, chatId } = useTelegram();

  const resBasket = products.map(product => ({
    goodCode: product.id,
    groupId: product.groupId,
    price: product.price,
    discount: product.discount,
    image: product.image,
    amount: product.amount,
    oldPrice: product.oldPrice,
    goodName: product.name,
    shopApiId: shopId,
    send: true,
  }));

  const response = await API.post(
    `/Catalog/Basket/State/?chatId=${chatId}&queryId=${queryId}&shopId=${shopId}`,
    resBasket,
  );
  return response.data;
};

export type SendBasketProductReqData = {
  goodCode: number;
  goodName: string;
  amount: number;
  oldPrice: number;
  newPrice: number;
};

export type SendBasketReqData = {
  shopFormat: string;
  shopAdress: string;
  economy: number;
  usersBasketDTOs: SendBasketProductReqData[];
};

export const sendBasketReq = async (resData: SendBasketReqData[], shopId: string) => {
  const { queryId, chatId } = useTelegram();

  await sendAPIFirst.post(
    `/Catalog/SendGoods/?shopId=${shopId}&userId=${chatId}&queryId=${queryId}`,
    resData,
  );

  if (!isDevApi) {
    await sendAPISecond.post(
      `/Catalog/SendGoods/?shopId=${shopId}&userId=${chatId}&queryId=${queryId}`,
      resData,
    );
  }
};

export const getSettingsReq = async (shopId: string) => {
  const { queryId, chatId } = useTelegram();

  const response = await sendAPIFirst.get(`/Catalog/GetSettings/?shopId=${shopId}`);
  return response.data;
};

export const clearBasketReq = async (shopId: string) => {
  const { queryId, chatId } = useTelegram();

  const response = await API.post(
    `/Catalog/Basket/Clear/?shopId=${shopId}&chatId=${chatId}&queryId=${queryId}`,
  );
  return response.data;
};

export const getShopFiltersReq = async (shopId: string) => {
  const { chatId } = useTelegram();

  const response = await API.get(`/Catalog/Filters/?shopId=${shopId}&chatId=${chatId}`);
  return response.data;
};

export const getShopsReq = async (shopId: string) => {
  const { chatId } = useTelegram();

  const response = await API.get(
    `/Shop/Nearby?shopId=${shopId}&latitude=${0}&longitude=${0}&chatId=${chatId}`,
  );
  return response.data;
};

export const getShopsByStreetReq = async (street: string): Promise<IShop[]> => {
  const { chatId } = useTelegram();

  const response = await API.get(
    `/Shop/Search?latitude=${0}&longitude=${0}&filter=${street}&chatId=${chatId}`,
  );
  return response.data;
};

export type getProductsType = {
  sale?: Interval;
  price?: Interval;
  sort?: SortType;
  shopId: string;
  searchString?: string;
  groupIds?: string;
};

export const getProductsReq = async (params: getProductsType) => {
  const { chatId } = useTelegram();
  const { sale, price, sort, searchString, groupIds, shopId } = params;

  const queryObjects = {
    ...{ ...(sale && { discountMin: sale.min }) },
    ...{ ...(sale && { discountMax: sale.max }) },
    ...{ ...(price && { priceMin: price.min }) },
    ...{ ...(price && { priceMax: price.max }) },
    sort: `${sort?.type || 'name'}|${sort?.order || 'asc'}`,
    ...{ ...(searchString && { filter: searchString }) },
    ...{ ...(groupIds && { groupIds }) },
  };
  const response = await API.get(
    `/Catalog/Groups/Goods?shopId=${shopId}&chatId=${chatId}&${qs.stringify(queryObjects)}`,
  );
  return response.data;
};

export const bindShopReq = async (shopId: string) => {
  const { chatId } = useTelegram();

  await API.put(`/Shop/Bind?shopId=${shopId}&chatId=${chatId}`);
};
