import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { CustomModal } from '../../../../components/CustomModal';
import { Filter } from '../../../../components/Filter';
import { Input } from '../../../../components/Input';
import { useAppDispatch, useAppSelector } from '../../../../hooks/redux';
import { getProductsType, getShopsReq } from '../../../../API';
import { fetchProducts } from '../../../../store/products/actions';
import { initialState, setSearchString } from '../../../../store/filter';
import { debounce, getInitialData } from '../../../../utils';
import { useNavigate, useSearchParams } from 'react-router-dom';

import './styles.css';

const queryParams = new URLSearchParams(window.location.search);
export const id = queryParams.get('latitude');

export const FilterByCategory: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { filters, categories, shops } = useAppSelector(state => state);

  const [isModalVisible, setModalVisible] = useState(false);
  const [isSearchVisible, setSearchVisible] = useState(false);

  const isFilterActive = useMemo(
    () =>
      JSON.stringify(filters.tempSale) !== JSON.stringify(filters.initSale) ||
      JSON.stringify(filters.tempPrice) !== JSON.stringify(filters.initPrice) ||
      JSON.stringify(filters.sort) !== JSON.stringify(initialState.sort),
    [filters],
  );

  const handleFetchProducts = debounce((searchString: string, shopApiId?: string) => {
    const params: getProductsType = {
      price: filters.tempPrice,
      sale: filters.tempSale,
      sort: filters.sort,
      searchString,
      groupIds: categories.selectedCategories.map(el => el.id).join(',') || '',
      shopId: shopApiId || shops?.currentShop?.apiId,
    };

    dispatch(fetchProducts(params));
  }, 400);

  useEffect(() => {
    const search = decodeURIComponent(location.pathname.replace('/search_', '').replace('/', ''));

    if (!search) {
      return;
    }

    (async () => {
      const shopId = localStorage.getItem('shopId');
      if (!shopId) {
        throw new Error('Shop ID не указан'); // или другая обработка ошибки
      }
      const curShop = await getShopsReq(shopId);
      if (curShop?.length) {
        await getInitialData(curShop[0], dispatch);
        handleChangeInput(search, curShop[0].apiId);
        setSearchVisible(true);
        navigate('/');
      }
    })();
  }, []);

  const handleChangeInput = useCallback(
    (value: string, shopApiId?: string) => {
      dispatch(setSearchString(value));
      handleFetchProducts(value, shopApiId);
    },
    [shops],
  );

  return (
    <>
      <div className="filter">
        <div className="filter__title">Скидки</div>
        <div className={`filter__icon-wrap ${isFilterActive ? 'filter__icon-wrap__active' : ''}`}>
          <div
            className={`filter__icon ${
              isFilterActive ? 'filter__full-icon' : 'filter__outside-icon'
            }`}
            onClick={() => setModalVisible(true)}
          />
        </div>
        <div
          className="filter__search-icon"
          onClick={() => {
            if (!filters.searchString) {
              setSearchVisible(prev => !prev);
            }
          }}
        />
        {isSearchVisible && (
          <div className="filter__input">
            <Input
              value={filters.searchString}
              onChange={handleChangeInput}
              placeholder={'Введите название товара...'}
            />
          </div>
        )}
      </div>

      {isModalVisible && (
        <CustomModal>
          <Filter onCloseModal={() => setModalVisible(false)} />
        </CustomModal>
      )}
    </>
  );
};
