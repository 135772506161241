import { ShopsState } from './types';
import { IShop } from '../../models/IShop';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchShops, fetchShopsByStreetName } from './actions';

const initialState: ShopsState = {
  currentShop: {} as IShop,
  shops: [],
  isLoadingShops: false,
  error: '',
  animationOff: false,
};

const shopsSlice = createSlice({
  name: 'shops',
  initialState,
  reducers: {
    setCurrentShop: (state, action: PayloadAction<IShop>) => {
      state.currentShop = action.payload;
    },
    setAnimation: (state, action: PayloadAction<boolean>) => {
      state.animationOff = action.payload;
    },
  },
  extraReducers: {
    [fetchShops.pending.type]: state => {
      state.isLoadingShops = true;
    },
    [fetchShops.fulfilled.type]: (state, action: PayloadAction<IShop[]>) => {
      state.isLoadingShops = false;
      state.error = '';
      state.shops = action.payload;
      state.currentShop = action.payload[0];
    },
    [fetchShops.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingShops = false;
      state.error = action.payload;
    },
    [fetchShopsByStreetName.pending.type]: state => {
      state.isLoadingShops = true;
    },
    [fetchShopsByStreetName.fulfilled.type]: (state, action: PayloadAction<IShop[]>) => {
      state.isLoadingShops = false;
      state.error = '';
      state.shops = action.payload;
    },
    [fetchShopsByStreetName.rejected.type]: (state, action: PayloadAction<string>) => {
      state.isLoadingShops = false;
      state.error = action.payload;
    },
  },
});

export const { setCurrentShop, setAnimation } = shopsSlice.actions;

const reducer = shopsSlice.reducer;
export default reducer;
